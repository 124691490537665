<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card>
      <v-card-title class="headline">{{ message.title }}</v-card-title>
      <v-card-text>{{ message.content }}</v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="blue darken-1" text @click="close">{{$t("common.disagree")}}</v-btn>
        <v-btn color="warning darken-1" text @click="confirm">{{$t("common.agree")}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  methods: {
    open (message) {
      this.dialog = true
      this.message = message
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    confirm () {
      this.resolve(true)
      this.dialog = false
    },
    close () {
      this.resolve(false)
      this.dialog = false
      this.message = {
        title: '',
        content:''
      }
    },
  },
  data () {
    return {
      dialog : false,
      resolve: null,
      reject : null,
      message: {
        title: '',
        content:''
      },
    }
  }
}</script>
