<template>
  <v-container fluid class="mt-8">
    <v-row>
      <v-col cols="4">
        <v-select
            :items="i18nStatus"
            item-text="text"
            item-value="value"
            v-model="searchByStatus"
            :label="$t('order.search_status')"
            dense
            clearable
            solo
        />
      </v-col>
      <v-col cols="4" v-if="permissions == 'admin'">
        <v-autocomplete
            v-model="selectedMerchant"
            :items="merchantsList"
            item-value="_id"
            item-text="name"
            :label="$t('merchant.name')"
            solo
            clearable
            dense
        />
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn dark :loading="excelLoading">
          <JsonExcel
              name="PlaysmartOrderServiceList.xls"
              :fetch="downoloadExcel"
          >
            {{ $t('order.export_data') }}
          </JsonExcel>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
        mobile-breakpoint=0
        class="elevation-4"
        sort-by="createdAt"
        :sort-desc="true"
        :headers="i18nHeader"
        :loading="loading"
        :items="orderList"
        :options.sync="options"
        :server-items-length="totalOrder"
        :footer-props="{'items-per-page-options': [10, 20, 30, 40, 50]}"
    >
      <template v-slot:item="{ item }">
        <tr>
          <!-- <td>{{ item.merchantId }} </td> -->
          <td>
            <v-icon @click="openChatRoom(item.userId._id, item.userName)" v-if="permissions == 'merchant'">
              mdi-message
            </v-icon>
          </td>
          <td>{{ item.userName }}</td>
          <!-- <td>{{ item.userEmail }} </td> -->
          <td>{{ item.serviceId }}</td>
          <td>{{ item.paymentMethod }}</td>
          <td>{{ item.amount }}</td>
          <td>{{ (item.receipt) ? 'Y' : 'N' }}</td>
          <!-- <td>{{ (item.serviceDateTimeId && item.serviceDateTimeId.startAt && item.serviceDateTimeId.endAt)? $moment(item.serviceDateTimeId.startAt).format('YYYY/MM/DD HH:mm') + ' - ' +  $moment(item.serviceDateTimeId.endAt).format('YYYY/MM/DD HH:mm') : '' }} </td> -->
          <td> {{ (item.serviceDateTimeId) ? handleDateTime(item.extra, item.serviceDateTimeId) : '' }}</td>
          <!-- <td>{{ item.sessions }} </td> -->
          <td>{{ showStatus(item.status) }}</td>
          <td> {{ $moment(item.createdAt).format('YYYY/MM/DD HH:mm') }}</td>
          <!-- <td> {{$moment(item.updatedAt).format('YYYY/MM/DD HH:mm')}}</td> -->
          <td>
            <v-icon @click="view(item)">
              mdi-pencil
            </v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
    <FormDialog
        ref="dialog"
        @refresh="getAllOrders"
        :merchantsList="merchantsList"
        :userList="userList"
        :serviceList="serviceList"
        :packageList="packageList"
        :sessionList="sessionList"
        :paymentMethodList="handledPaymentMethodList"
        :i18nStatus="i18nStatus"
    />
    <confirmDialog ref="confirmDialog"/>
    <ChatRoom ref="chatroom"/>
  </v-container>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
import FormDialog               from "@/views/Order/OrderDialog"
import confirmDialog            from "@/components/ConfirmDialog"
import JsonExcel                from "vue-json-excel"
import ChatRoom                 from "@/views/Message/ChatRoom"
import api                      from '@/api'


export default {
  components : {
    FormDialog,
    confirmDialog,
    ChatRoom,
    JsonExcel,
  },
  mounted () {
    // this.getAllOrders()
    this.getAllMerchant()
    this.getAllUser()
    this.getAllPaymentMethod()
    this.getAllSessions()
    this.getAllService()
    this.getAllPackage()
  },
  computed : {
    ...mapState(['lang', 'permissions']),
    i18nHeader () {
      let result = this.headers

      result.map(item => {
        item.text = this.$t(item.i18n)
      })

      return result
    },
    i18nStatus () {
      let result = this.statusArr

      result.map(item => {
        item.text = this.$t(item.i18n)
      })

      return result
    },
    handledPaymentMethodList () {
      let result = this.paymentMethodList

      result.map(item => {
        item.name = item[(this.lang == 'tc') ? 'nameZh' : 'nameEng']
      })

      return result
    }
  },
  watch    : {
    options          : {
      handler () {
        this.getAllOrders()
      }
    },
    selectedMerchant : {
      handler () {
        this.getAllOrders()
      }
    },
    searchByStatus   : {
      handler () {
        this.getAllOrders()
      }
    },
  },
  methods  : {
    ...mapMutations(['PUSH_GLOBAL_SNACK_MESSAGES']),
    async openChatRoom (userId, userName) {
      let findConversationId = await api.get('/message/findMyConversationIdWithUserId/' + userId)
      if (findConversationId.data) {
        this.$refs.chatroom.open(findConversationId.data.conversationId, userName, userId)
      }
    },
    async getAllOrders () {
      this.loading = true

      if (this.$route.query && this.$route.query.id) {
        this.selectedMerchant = this.$route.query.id
        this.$route.query.id = ''
      }

      try {
        var { sortBy, sortDesc, page, itemsPerPage } = this.options
        let order = (sortDesc[0]) ? -1 : 1
        let params = {
          page    : page,
          perPage : itemsPerPage,
          sort    : sortBy,
          order   : order,
        }

        if (this.selectedMerchant != '') Object.assign(params, { merchantId : this.selectedMerchant })
        if (this.searchByStatus != '') Object.assign(params, { status : this.searchByStatus })

        let result = await api.get('/orders', { params })

        if (result) {
          this.orderList = result.data.docs
          this.totalOrder = result.data.totalDocs

          if (sortBy.length === 1 || sortDesc.length === 1) {
            this.orderList = this.orderList.sort((a, b) => {
              const sortA = a[sortBy[0]]
              const sortB = b[sortBy[0]]

              if (sortDesc[0]) {
                if (sortA < sortB) return 1
                if (sortA > sortB) return -1
                return 0
              } else {
                if (sortA < sortB) return -1
                if (sortA > sortB) return 1
                return 0
              }
            })
          }

          this.orderList.map((item, index) => {
            if (item.merchantId) this.orderList[index].merchantId = item.merchantId.name
            if (item.serviceId) this.orderList[index].serviceId = item.serviceId.name
            if (item.paymentMethod) this.orderList[index].paymentMethod = item.paymentMethod[(this.lang == 'tc') ? 'nameZh' : 'nameEng']
            if (item.userId) this.orderList[index].userEmail = item.userId.email
            if (item.userId) this.orderList[index].userName = item.userId.name
          })

        }
      } catch (error) {
        this.PUSH_GLOBAL_SNACK_MESSAGES({
          message : error.response.data.message,
          icon    : 'mdi-close',
        })
        this.loading = false
      }
      this.loading = false
    },
    async getAllMerchant () {
      let apiTarget = (this.permissions == 'admin') ? '/merchants/all' : '/merchants/published'

      try {
        let result = await api.get(apiTarget)

        if (result) {
          this.merchantsList = (this.permissions == 'admin') ? result.data : result.data.docs
        }
      } catch (error) {
        this.PUSH_GLOBAL_SNACK_MESSAGES({
          message : error.response.data.message,
          icon    : 'mdi-close',
        })
      }
    },
    async getAllUser () {
      let apiTarget = (this.permissions == 'admin') ? '/users/all' : '/users'

      try {
        let result = await api.get(apiTarget)

        if (result) {
          this.userList = result.data
        }
      } catch (error) {
        this.PUSH_GLOBAL_SNACK_MESSAGES({
          message : error.response.data.message,
          icon    : 'mdi-close',
        })
      }
    },
    async getAllPaymentMethod () {
      let apiTarget = (this.permissions == 'admin') ? '/paymentMethod' : '/paymentMethod/published'

      try {
        let result = await api.get(apiTarget) 

        if (result) {
          this.paymentMethodList = result.data
        }
      } catch (error) {
        this.PUSH_GLOBAL_SNACK_MESSAGES({
          message : error.response.data.message,
          icon    : 'mdi-close',
        })
      }
    },
    async getAllPackage () {
      try {
        let result = await api.get('/servicePackages/all')

        if (result) {
          this.packageList = result.data
        }
      } catch (error) {
        this.PUSH_GLOBAL_SNACK_MESSAGES({
          message : error.response.data.message,
          icon    : 'mdi-close',
        })
      }
    },
    async getAllService () {
      try {
        let result = await api.get('/services/all')

        if (result) {
          this.serviceList = result.data
        }
      } catch (error) {
        this.PUSH_GLOBAL_SNACK_MESSAGES({
          message : error.response.data.message,
          icon    : 'mdi-close',
        })
      }
    },
    async getAllSessions () {
      try {
        let result = await api.get('/servicePackageSessions/all')

        if (result) {
          this.sessionList = result.data
        }
      } catch (error) {
        this.PUSH_GLOBAL_SNACK_MESSAGES({
          message : error.response.data.message,
          icon    : 'mdi-close',
        })
      }
    },
    async downoloadExcel () {
      this.excelLoading = true
      let result = ''
      let resultArr = []

      try {
        result = await api.get('/orders/all')

        for (const [key, value] of Object.entries(result.data)) {
          console.log(key);
          //console.log(value);
          var json = {
                id                   : value._id,
                userName             : (value.userId) ? value.userId.name : "--",
                hasReceipt           : (value.receipt) ? 'Y' : 'N',
                paymentMethodNameZh  : (value.paymentMethod) ? value.paymentMethod.nameZh : '--',
                paymentMethodNameEng : (value.paymentMethod) ? value.paymentMethod.nameEng : '--',
                merchantName         : (value.merchantId) ? value.merchantId.name : "--",
                service              : (value.serviceId) ? value.serviceId.name : "--",
                amount               : value.amount,
                teacherNameZH        : value.teacher.nameZh,
                teacherNameEng       : value.teacher.nameEng,
                status               : value.status,
                price                : value.price,
                createdAt            : this.$moment(value.createdAt).format('YYYY/MM/DD HH:mm'),
                updatedAt            : this.$moment(value.updatedAt).format('YYYY/MM/DD HH:mm')
              };
              //console.log(json);
          resultArr.push(json);
        }
      } catch (error) {
        //console.log(error);
        this.PUSH_GLOBAL_SNACK_MESSAGES({
          message : error.response.data.message,
          icon    : 'mdi-close',
        })
      }
      this.excelLoading = false
      console.log(resultArr);
      return resultArr;
    },
    showStatus (status) {
      return this.i18nStatus.find(item => item.value == status).text
    },
    create () {
      this.$refs.dialog.open('create', '', 'service')
    },
    view (item) {
      this.$refs.dialog.open('edit', item, 'service')
    },
    handleDateTime (extra, sdt) {
      let result = ''
      var start = this.convertExtraTime(extra, sdt, true);
      var end = this.convertExtraTime(extra, sdt, false);

      if (start && end) {
        if (this.$moment(start).format('YYYY/MM/DD') == this.$moment(end).format('YYYY/MM/DD')) {
          result = this.$moment(start).format('YYYY/MM/DD') + ' ' + this.$moment(start)
                                                                        .format('HH:mm') + ' - ' + this.$moment(end)
                                                                                                       .format('HH:mm')
        } else {
          result = this.$moment(start).format('YYYY/MM/DD HH:mm') + ' - ' + this.$moment(end).format('YYYY/MM/DD HH:mm')
        }
      }

      return result
    },
    convertExtraTime(extra, sdt, isStart) {
      var original = new Date(sdt.startAt);
      if(extra){
        var locale = new Date(original.getTime() + 8*60*60*1000);
        var newstart = new Date(locale.toISOString().substring(0,10) + "T" +extra["startAt"]+":00");
        if(isStart){
          return newstart;
        } else {
          var newend = new Date(newstart.getTime() + parseFloat(extra["duration"]) * 60 * 60 * 1000);
          return newend;
        }

      } else {
        if(isStart){
          return original;
        } else {
          return new Date(sdt.endAt);
        }
      }
    },
  },
  data     : () => ({
    headers                 : [
      // {i18n: 'common.merchant',value: 'merchantId'},
      { i18n : '', value : 'message', sortable : false },
      { i18n : 'common.user', value : 'userName' },
      // {i18n: 'common.email_user',value: 'userEmail'},
      { i18n : 'common.service', value : 'serviceId' },
      { i18n : 'common.payment_method', value : 'paymentMethod' },
      { i18n : 'common.amount', value : 'amount' },
      { i18n : 'common.receipt', value : 'receipt' },
      { i18n : 'common.order_time', value : '', sortable : false },
      { i18n : 'common.status', value : 'status' },
      { i18n : 'common.created_at', value : 'createdAt' },
      // {i18n: 'common.updated_at', value: 'updatedAt'},
      { i18n : 'common.edit', value : '', sortable : false },
    ],
    statusArr               : [
      { i18n : 'order.waiting_payment', value : 'waitingForPayment' },
      { i18n : 'order.waiting_confirm', value : 'waitingForConfirm' },
      { i18n : 'order.confirmed', value : 'confirmed' },
      { i18n : 'order.cancelled', value : 'cancelled' },
    ],
    searchByName            : '',
    teacherList             : [],
    merchantsList           : [],
    serviceList             : [],
    paymentMethodList       : [],
    sessionList             : [],
    userList                : [],
    orderList               : [],
    packageList             : [],
    loading                 : false,
    excelLoading            : false,
    searchByStatus          : 'confirmed',
    selectedMerchant        : null,
    displaySelectedMerchant : '',
    totalOrder              : 0,
    options                 : {}
  })
}
</script>
