<template>
    <v-container>
        <v-dialog v-model="dialog" persistent fullscreen>
            <v-toolbar dark>
                <v-btn icon @click="close()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{ $t('order.' + action + '_package') }}</v-toolbar-title>
                <v-spacer />
                <v-btn dark text @click="save(action)">{{ $t('common.save') }}</v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-form ref="form" v-model="formValid" lazy-validation>
                            <v-row>
                                <v-col cols="6">

                                    <v-select v-model="orderPackageDetailObj.merchantId"
                                        :items="merchantsList.length == 1 ? [orderPackageDetailObj.merchantId] : merchantsList"
                                        item-value="_id" item-text="name" :label="$t('merchant.name')" disabled />
                                    <!--
                                    <v-text-field
                                        :value="orderPackageDetailObj.merchantId.name"
                                        :label="$t('merchant.name')"
                                        disabled
                                    />
                                    -->
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        :value="(orderPackageDetailObj.userId) ? orderPackageDetailObj.userId.email : ''"
                                        :label="$t('common.user')" disabled />
                                </v-col>

                                <v-col cols="6" v-if="permissions == 'admin'">
                                    <v-text-field v-model="orderPackageDetailObj.username" :label="$t('common.user_name')"
                                        disabled />
                                </v-col>
                                <v-col cols="6" v-if="permissions == 'admin'">
                                    <v-text-field v-model="orderPackageDetailObj.phoneno" :label="$t('common.phone')"
                                        disabled />
                                </v-col>

                                <v-col cols="6">
                                    <v-select v-model="orderPackageDetailObj.status" :items="i18nStatus" item-value="value"
                                        item-text="text" :label="$t('common.status')" />
                                </v-col>
                                <v-col cols="6">
                                    <v-select v-model="orderPackageDetailObj.paymentMethod" :items="paymentMethodList"
                                        item-value="_id" item-text="name" :label="$t('common.payment_method')" disabled />
                                </v-col>
                            </v-row>
                            <v-row v-if="isExtra">
                                <v-col cols="3">
                                    <v-select v-model="startAt" :items="generateAllDaysTimeSlot"
                                        :label="$t('common.startAt')" :rules="[v => !!v || $t('common.required')]" />
                                </v-col>
                                <v-col cols="3" v-if="this.extra.mode == 'allcustom'">
                                    <v-select v-model="duration" :items="generateSlotLength"
                                        :label="$t('service_date_times.hour_length')"
                                        :rules="[v => !!v || $t('common.required')]" />
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field v-model="price" :rules="[
                                        v => !!v || $t('common.required'),
                                        v => /^[0-9][0-9]*$/.test(v) || $t('common.valid_number')
                                    ]" :label="$t('common.price')" />

                                </v-col>



                            </v-row>
                            <v-row v-if="orderType == 'package'">
                                <v-col cols="12">
                                    <v-text-field v-model="orderPackageDetailObj.packageName" :label="$t('common.package')"
                                        disabled />
                                </v-col>
                                <!-- <v-col cols="6">
                                    <v-simple-table dense class="elevation-1">
                                        <template v-slot:default>
                                        <thead>
                                            <tr v-for="(item, index) in i18nSessionHeaders" :key="index">
                                                <th class="text-left">
                                                    {{item.text}}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="session in orderPackageDetailObj.sessions"
                                                :key="session._id"
                                            >
                                                <td>{{ showSessionInfo(session.packageSessionId) }} </td>
                                            </tr>
                                        </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col> -->
                            </v-row>
                            <v-row v-else>
                                <v-col cols="7">
                                    <v-text-field
                                        :value="(orderPackageDetailObj.serviceId && orderPackageDetailObj.serviceId != '') ? orderPackageDetailObj.serviceId.name : orderPackageDetailObj.serviceId"
                                        :label="$t('common.service')" disabled />
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field
                                        :value="(orderPackageDetailObj.teacher) ? orderPackageDetailObj.teacher[(lang == 'tc') ? 'nameZh' : 'nameEng'] : ''"
                                        :label="$t('common.teacher')" disabled />
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field v-model="orderPackageDetailObj.amount" :label="$t('common.amount')"
                                        disabled />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" v-if="orderPackageDetailObj.receipt">
                                    <v-card>
                                        <v-card-title>{{ $t('order.image') }}</v-card-title>
                                        <v-card-text>
                                            <v-img class="mx-auto" :src="orderPackageDetailObj.receipt.large.path"
                                                max-width="500" />
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-row v-if="action == 'edit'">
                                    <v-col cols="6">
                                        <v-text-field
                                            :value="$moment(orderPackageDetailObj.createdAt).format('YYYY/MM/DD HH:mm')"
                                            disabled :label="$t('common.created_at')" />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            :value="$moment(orderPackageDetailObj.updatedAt).format('YYYY/MM/DD HH:mm')"
                                            disabled :label="$t('common.updated_at')" />
                                    </v-col>
                                </v-row>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import api from '@/api'

export default {
    components: {
    },
    mounted() { },
    computed: {
        ...mapState(['lang', 'permissions',]),
        i18nSessionHeaders() {
            let result = this.sessionHeaders

            result.map(item => {
                item.text = this.$t(item.i18n)
            })

            return result
        },
        generateAllDaysTimeSlot() {
            let result = [
                "00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30",
                "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30",
                "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30", "24:00",
            ]

            return result
        },
        generateSlotLength() {
            let result = []
            for (let i = 0; i < 96; i++) {
                var time = (i + 1) * 0.5;
                result.push(time.toFixed(1));
            }

            return result
        },
    },
    props: ['merchantsList', 'userList', 'packageList', 'paymentMethodList', 'i18nStatus', 'sessionList', 'serviceList'],
    methods: {
        ...mapMutations(['PUSH_GLOBAL_SNACK_MESSAGES']),
        async open(action, item, orderType) {
            if (action == 'edit') {
                let result = false

                this.apiTarget = (orderType == 'package') ? 'packageOrders' : 'orders'
                this.orderType = orderType

                try {
                    result = await api.get('/' + this.apiTarget + '/' + item._id)
                } catch (error) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: error.response.data.message,
                        icon: 'mdi-close',
                    })
                }

                if (result) {
                    this.orderPackageDetailObj = result.data
                    this.price = this.orderPackageDetailObj.price;
                    this.oldStatus = this.orderPackageDetailObj.status;
                    if (this.orderPackageDetailObj.extra) {
                        this.isExtra = true;
                        this.extra = this.orderPackageDetailObj.extra;
                        this.startAt = this.orderPackageDetailObj.extra.startAt;
                        this.duration = this.orderPackageDetailObj.extra.duration;
                    }
                    if (orderType == 'package') this.orderPackageDetailObj.packageName = this.orderPackageDetailObj.packageId.name
                }
            }

            this.action = action
            this.dialog = true
        },
        async save() {
            let validated = this.$refs.form.validate()

            if (validated) {
                let result = false
                this.loading = true

                if (this.orderPackageDetailObj.receipt == '') {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.warning_upload_img'),
                        icon: 'mdi-close',
                    })

                    return false
                }

                try {
                    var orderId = this.orderPackageDetailObj._id;
                    var userId = this.orderPackageDetailObj.userId._id;
                    var autoAction = "";
                    if (this.orderPackageDetailObj.status != this.oldStatus) {
                        if (this.orderPackageDetailObj.status == "waitingForConfirm") {
                            autoAction = "Available"
                        }
                        if (this.orderPackageDetailObj.status == "confirmed") {
                            autoAction = "Confirmed"
                        }
                        if (this.orderPackageDetailObj.status == "cancelled") {
                            autoAction = "Cancelled"
                        }
                    }
                    if (this.action == 'create') {
                        result = await api.post('/' + this.apiTarget, this.orderPackageDetailObj)
                    } else {
                        if (this.isExtra) {
                            if (this.startAt != this.orderPackageDetailObj.extra.startAt) {
                                this.orderPackageDetailObj.extra.startAt = this.startAt;
                            }
                            if (this.duration != this.orderPackageDetailObj.extra.duration) {
                                this.orderPackageDetailObj.extra.duration = this.duration;
                            }
                            if (parseInt(this.price) != this.orderPackageDetailObj.price) {
                                this.orderPackageDetailObj.price = parseInt(this.price);
                            }
                        }
                        //console.log(this.orderPackageDetailObj);

                        result = await api.patch('/' + this.apiTarget + '/' + this.orderPackageDetailObj._id, this.orderPackageDetailObj)
                    }

                    if (result) {
                        let targetI18n = (this.action === 'edit') ? 'global_alert.success_edit' : 'global_alert.success_create'
                        var newobject = this.orderPackageDetailObj;
                        this.resetForm()
                        this.dialog = false
                        this.PUSH_GLOBAL_SNACK_MESSAGES({
                            message: this.$t(targetI18n),
                            icon: 'mdi-check',
                        })
                        this.$emit('updateStatus', newobject);
                        console.log(this.orderPackageDetailObj);
                        if (autoAction != "" && this.orderType != "package") {
                            this.sendAutoMessage(userId, autoAction, orderId);
                        }
                    }
                } catch (error) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: error.response.data.message,
                        icon: 'mdi-close',
                    })
                }

                this.loading = false
            }
        },
        async sendAutoMessage(userId, action, orderId,) {
            console.log(userId);

            try {
                await api.post('/message/auto', {
                    "to": userId,
                    "content": action + "_" + orderId,
                })
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon: 'mdi-close',
                })
            }
        },
        showSessionInfo(id) {
            let result = ''

            this.sessionList.map(item => {
                // if(item._id == id){
                //     if(item.name && item.name != ''){
                //         result = item.name
                //     }else{
                //         if(item.availableServices){
                //             item.availableServices.map(serviceItem => {
                //                 for (const [key, value] of Object.entries(this.serviceList)) {
                //                     console.log(serviceItem._id);
                //                     console.log(value);
                //                     if(value._id == serviceItem._id){
                //                         console.log(value);
                //                     }
                //                 }
                //                 // serviceItem.service
                //             })
                //         }
                //     }

                // }

                if (item._id == id) result = item.name
            })

            return result
        },

        close() {

            this.resetForm()
            this.dialog = false

        },
        resetForm() {
            this.$emit('refresh')
            this.uploadImageFile = []
            this.orderPackageDetailObj = {}
        },
    },
    data: () => ({
        dialog: false,
        uploadImageFile: [],
        sessionHeaders: [
            { i18n: 'common.sessions', value: 'packageSessionId' },
        ],
        orderPackageDetailObj: {},
        apiTarget: '',
        orderType: '',
        action: 'edit',
        isExtra: false,
        price: 0,
        extra: null,
        startAt: '',
        duration: '',
        oldStatus: "",
        formValid: true,
        openMenu: false,
        closeMenu: false,
    })
}
</script>